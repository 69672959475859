import moment from "moment";

import { DEFAULT_CATERING_TIME_TO_CONFIRM, GLOBAL_TIP_PRODUCT_NAME, PaymentMethod } from "Constants";
import { javaDateToDate } from "Utils";
import {
    EatingPreference,
    EatingOption,
    OnlineEatingPreference,
    Order,
    PaymentMethodAmount,
    WebOrderType
} from "Types";

const isCatering = (eatingPreference: EatingPreference | EatingOption | "") =>
    !!eatingPreference && eatingPreference === EatingPreference.CATERING;

const getCateringMinutesToConfirm = (cateringConfirmationDate: string, backendDiff: number) => {
    return moment(javaDateToDate(cateringConfirmationDate)).diff(moment().add(backendDiff, "ms"), "minutes");
};

const getEarliestHourToConfirmCatering = (pickupDate: string, pickupTime: string, timeToConfirm: number | null) => {
    if (!!timeToConfirm) {
        return timeToConfirm;
    }
    const pickupDateTime = moment(`${pickupDate} ${pickupTime}`, "YYYY-MM-DD HH:mm");
    const now = moment();
    const hoursUntilPickup = pickupDateTime.diff(now, "hours");
    return Math.min(hoursUntilPickup, DEFAULT_CATERING_TIME_TO_CONFIRM);
};

const hasPaymentMethod = (order: Order, paymentMethod: PaymentMethod): boolean => {
    return order.paymentMethodAmounts.some(
        (paymentMethodAmount: PaymentMethodAmount) => paymentMethodAmount.paymentMethod === paymentMethod
    );
};

const hasGiftCardPayment = (order: Order): boolean => {
    return order.paymentMethodAmounts.some(
        (paymentMethodAmount: PaymentMethodAmount) => paymentMethodAmount.paymentMethod === PaymentMethod.GIFT_CARD
    );
};

const hasTipProduct = (order: Order): boolean => {
    return !!order.orderProducts.find(orderProduct => orderProduct.name === GLOBAL_TIP_PRODUCT_NAME);
};

const getWebOrderType = (eatingPreference: OnlineEatingPreference): WebOrderType => {
    if (eatingPreference === EatingPreference.CATERING_DELIVERY) {
        return WebOrderType.CATERING_DELIVERY;
    } else if (eatingPreference === EatingPreference.CATERING_TAKE_AWAY) {
        return WebOrderType.CATERING_TAKE_AWAY;
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return WebOrderType.HOME_DELIVERY;
    } else {
        return WebOrderType.BASIC;
    }
};

export {
    isCatering,
    getEarliestHourToConfirmCatering,
    getCateringMinutesToConfirm,
    hasPaymentMethod,
    hasGiftCardPayment,
    hasTipProduct,
    getWebOrderType
};
