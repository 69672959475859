// import { PHONE_NUMBER_MASKS } from "Constants";
import { StringOrNull } from "Types";
import { parsePhoneNumber } from "libphonenumber-js";

export const isValidLengthPhoneNumber = (phoneNumber: string) => new RegExp(/\d{8,14}/g).test(phoneNumber);

export const hasZeroAfterDialCode = (phoneNumber: string, dialCode: any) =>
    phoneNumber.substring(dialCode.length).startsWith("0");

export const formatSwedishPhoneNumber = (phoneNumberString: StringOrNull | undefined) => {
    try {
        if (!phoneNumberString) return undefined;

        const phoneNumber = parsePhoneNumber(phoneNumberString);
        return phoneNumber.formatInternational();
    } catch (error) {
        console.log(`Couldn't parse phone number: ${error}`);
    }
};

/**
 * This was used in formatSwedishPhoneNumber before the rebase.
 * I'm leaving this here for now
 */
// const getPhoneMaskForCountry = (phoneNumber: string) => {
//     if (phoneNumber.startsWith("+46")) return PHONE_NUMBER_MASKS.SE;
//     if (phoneNumber.startsWith("+47")) return PHONE_NUMBER_MASKS.NO;
//     if (phoneNumber.startsWith("+358")) return PHONE_NUMBER_MASKS.FI;

//     //no formatting will be applied
//     return "";
// };

export const appendPlusIfMissing = (phoneNumber: string) => {
    if (!phoneNumber.startsWith("+")) {
        return "+" + phoneNumber;
    }
    return phoneNumber;
};

export const appendDefaultCountryCodeIfMissing = (phoneNumber: string) => {
    if (phoneNumber.startsWith("0")) {
        return "+46" + phoneNumber.substring(1);
    } else return phoneNumber;
};

export const appendCountryCodeIfMissing = (phoneNumber?: string) => {
    if (!phoneNumber) {
        return undefined;
    }

    if (phoneNumber.startsWith("+46") || phoneNumber.length > 10) {
        if (phoneNumber.startsWith("46")) {
            return `+${phoneNumber}`;
        } else {
            return phoneNumber;
        }
    }

    if (phoneNumber.startsWith("0")) {
        return `+46${phoneNumber.slice(1)}`;
    } else {
        return `+46${phoneNumber}`;
    }
};

export const removeCountryCode = (phoneNumber?: string) => {
    if (!phoneNumber) {
        return undefined;
    }

    if (phoneNumber.startsWith("+46")) {
        return `0${phoneNumber?.slice(3)}`;
    } else {
        return phoneNumber;
    }
};

export const extractDialCode = (phoneNumberString: string) => {
    try {
        const phoneNumber = parsePhoneNumber(appendPlusIfMissing(phoneNumberString));
        return phoneNumber.countryCallingCode;
    } catch {
        return "";
    }
};

export const validatePhoneNumber = (phoneNumberString: string) => {
    try {
        const dialCode = extractDialCode(phoneNumberString);
        const hasZeroAfterDialCode = phoneNumberString.replace(`+${dialCode}`, "").startsWith("0");

        if (hasZeroAfterDialCode) {
            return { isValid: false, error: "zeroAfterDialCodeWithArg" };
        }

        const phoneNumber = parsePhoneNumber(phoneNumberString);
        if (!phoneNumber.isValid()) {
            throw "invalid";
        }

        return { isValid: true };
    } catch {
        return { isValid: false, error: "phoneNumberErrorWithArg" };
    }
};

export const checkPhoneNumberExists = (phoneNumberString: StringOrNull | undefined) =>
    (phoneNumberString && phoneNumberString?.replace("+", "").length > 0) ?? false;
